import { defineStore } from 'pinia';
import { initializeApp } from "firebase/app";
import { deleteToken, getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "axios";

export const useFcmStore = defineStore('fcm', {
    state: () => ({
        messaging: {},
        token: '',
        tokenSentToServer: false,
        serviceWorkerRegistration: {},
    }),
    getters: {

    },
    actions: {
        async firebaseInit(onPushMessageReceived) {
            const firebaseConfig = {
                apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
                authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
                projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
                storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
                messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
                appId: import.meta.env.VITE_FIREBASE_APP_ID,
            };
            this.serviceWorkerRegistration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/' });
            const app = initializeApp(firebaseConfig);
            this.messaging = getMessaging(app);

            onMessage(this.messaging, (payload) => {
                onPushMessageReceived(payload);
            });

            const channel = new BroadcastChannel('sw-messages');
            channel.addEventListener("message", function (event) {
                let url = new URL(event.data.data.clickAction);
                window.location.href = url.pathname + url.search + url.hash;
            })
        },
        async requestPermission() {
            try {
                if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers['push-permission-request']) {
                    window.webkit.messageHandlers['push-permission-request'].postMessage('push-permission-request');
                    window.addEventListener('push-permission-request', (message) => {
                        if (message && message.detail) {
                            switch (message.detail) {
                                case 'granted':
                                    window.webkit && window.webkit.messageHandlers['push-token-request'].postMessage('push-token-request');
                                    break;
                                default:
                                    break;
                            }
                        }
                    });
                    window.addEventListener('push-token-request', (message) => {
                        console.log(message);
                        if (message && message.detail) {
                            let token = message.detail;
                            this.setToken(token);
                            this.sendTokenToServer(token);
                        }
                    });
                }
            } catch (e) {
                console.log(e);
            }

            try {
                if (!this.token) {
                    const permission = await Notification.requestPermission();
                    if (permission === "granted") {
                        const token = await getToken(this.messaging, {
                            vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
                            serviceWorkerRegistration: this.serviceWorkerRegistration,
                        });
                        this.setToken(token);
                        this.sendTokenToServer(token);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        },
        async deleteToken() {
            if (this.token) {
                await axios.post(route('firebase.token.delete'), { token: this.token });
            }
            try {
                await deleteToken(this.messaging);
                this.setToken('');
                this.setTokenSentToServer('');
                localStorage.removeItem('sentFirebaseMessagingToken');
            } catch (e) {
                console.log(e);
            }
        },
        async sendTokenToServer(token) {
            let localToken = localStorage.getItem('sentFirebaseMessagingToken');
            if (this.tokenSentToServer !== token && localToken !== token) {
                console.log('sendTokenToServer save DB');
                const response = await axios.post(route('firebase.token'), { token: token });
                if (response.status === 200) {
                    this.setTokenSentToServer(token);
                }
            }
        },
        setToken(token) {
            this.token = token;
        },
        setTokenSentToServer(token) {
            this.tokenSentToServer = token;
            localStorage.setItem('sentFirebaseMessagingToken', token ? token : false);
        },
        setMessaging(messaging) {
            this.messaging = messaging;
        },
        setServiceWorkerRegistration(serviceWorkerRegistration) {
            this.serviceWorkerRegistration = serviceWorkerRegistration;
        }
    },
});
